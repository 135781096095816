import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Blog, ReadingTime } from '@core/blog/blog.types';
import { CacheService } from '@core/cache/cache.service';
import { MerchantService } from '@core/merchant/merchant.service';
import { Merchant } from '@core/merchant/merchant.types';
import { EnvironmentService } from '@core/utils/environment/environment.service';
import { DEFAULT_HTTP_OPTIONS_NO_EXPIRE, HttpOptions } from '@core/utils/http-options.types';
import { environment } from '@env/environment';
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs';


/**
 * Service for managing blogs.
 */
@Injectable({
  providedIn: 'root'
})
export class BlogService {

  /**
   * The merchant associated with the blogs.
   */
  private _merchant: Merchant;

  /**
   * The number of blogs.
   */
  private _blogCount: BehaviorSubject<number> = new BehaviorSubject(0);

  /**
   * The currently selected blog.
   */
  private _blog: BehaviorSubject<Blog | null> = new BehaviorSubject(null);

  /**
   * The list of blogs.
   */
  private _blogs: BehaviorSubject<Blog[]> = new BehaviorSubject([]);

  /**
   * The cache namespace.
   */
  private _cacheNamespace: string = 'blog';

  /**
   * Creates an instance of BlogService.
   * @param _environmentService EnvironmentService instance for getting API URL.
   * @param _httpClient The HttpClient service.
   * @param _merchantService The MerchantService service.
   * @param _cacheService The cache service.
   */
  constructor(
    private _environmentService: EnvironmentService,
    private _httpClient: HttpClient,
    private _merchantService: MerchantService,
    private _cacheService: CacheService
  ) {
    // Get the merchant
    this._merchantService.merchant$
      .subscribe((merchant: Merchant) => {
        const reload = this._merchant?._id.toString() !== merchant?._id.toString();
        this._merchant = merchant;
        if (reload) {
          this._blog.next(null);
          this.getBlogs({})
            .subscribe();
          this.countBlogs({})
            .subscribe();
        }
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for blogs.
   * @returns An observable that emits the list of blogs.
   */
  get blogs$(): Observable<Blog[]> {
    return this._blogs.asObservable();
  }
  
  /**
   * Getter for the selected blog.
   * @returns An observable that emits the currently selected blog.
   */
  get blog$(): Observable<Blog> {
    return this._blog.asObservable();
  }

  /**
   * Setter for the selected blog.
   * @param blog The new selected blog.
   */
  set blog(blog: Blog) {
    this._blog.next(blog);
  }

  /**
  * Getter for the number of blogs.
  * @returns An observable that emits the number of blogs.
  */
  get blogCount$(): Observable<number> {
    return this._blogCount.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Counts the number of words in an HTML string.
   * 
   * @param html - The HTML string to count words from.
   * @returns The number of words in the HTML string.
   */
  private _countWordsInHtml(html: string): number {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const text = doc.body.textContent || '';
      const words = text
        .trim()
        .split(/\s+/)
        .filter(word => word !== '');
      return words.length;
    } catch (error) {
      return 0;
    }
  }
  
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Returns a blog object with the specified blogId.
   * @param blogId - The ID of the blog to fetch.
   * @param options - The options related to cache and propagation.
   * @returns The requested blog object.
   */
  getBlogById(blogId: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Blog> {
    // If there is no merchant, return null
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    // If the blog is already loaded, return it.
    // If the blog is not found, do not record the cache miss yet.
    const cacheParams: any = { merchantId: this._merchant._id, blogId };
    if (options.cache) {
      if (this._cacheService.has(this._cacheNamespace, 'blog', { merchantId: this._merchant._id, blogId }, false)) {
        this._cacheService.updateStats(true);
        const blog: Blog = this._cacheService.get(this._cacheNamespace, 'blog', { merchantId: this._merchant._id, blogId });
        if (options.forcePropagate) {
          this._blog.next(blog);
        }
        return of(blog);
      }
      // Check if the blog is one of the previously loaded sets of blogs
      const blogSets: Blog[][] = this._cacheService.get(this._cacheNamespace, 'blogs', null);
      if (blogSets) {
        for (const blogs of blogSets) {
          const blog: Blog = blogs.find(b => b._id === blogId);
          if (blog) {
            if (options.cache) {
              this._cacheService.set(this._cacheNamespace, 'blog', cacheParams, blog, options.expire);
            }
            if (options.propagate) {
              this._blog.next(blog);
            }
            // Record the cache hit.
            this._cacheService.updateStats(true);
            return of(blog);
          }
        }
      }
      // Record the cache miss.
      this._cacheService.updateStats(false);
    }
    // Otherwise, load the blog from the backend
    return this._httpClient.get<Blog>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/blogs/${blogId}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of(null);
        }),
        // Handle the logic of the cache and the propagation
        tap((blog: Blog) => {
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'blog', cacheParams, blog, options.expire);
          }
          if (options.propagate) {
            this._blog.next(blog);
          }
        })
      );
  }

  /**
   * Returns a blog with the given slug.
   * @param blogSlug - The slug of the blog to retrieve.
   * @param options - The options related to cache and propagation.
   * @returns The requested blog object.
   */
  getBlogBySlug(blogSlug: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Blog> {
    // If there is no merchant, return null
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    // If the blog is already loaded, return it.
    // If the blog is not found, do not record the cache miss yet.
    const cacheParams: any = { merchantId: this._merchant._id, blogSlug };
    if (options.cache) {
      if (this._cacheService.has(this._cacheNamespace, 'blog', { merchantId: this._merchant._id, blogSlug }, false)) {
        this._cacheService.updateStats(true);
        const blog: Blog = this._cacheService.get(this._cacheNamespace, 'blog', { merchantId: this._merchant._id, blogSlug });
        if (options.forcePropagate) {
          this._blog.next(blog);
        }
        return of(blog);
      }
      // Check if the blog is one of the previously loaded sets of blogs
      const blogSets: Blog[][] = this._cacheService.get(this._cacheNamespace, 'blogs', null);
      if (blogSets) {
        for (const blogs of blogSets) {
          const blog: Blog = blogs.find(b => b.slug === blogSlug);
          if (blog) {
            if (options.cache) {
              this._cacheService.set(this._cacheNamespace, 'blog', cacheParams, blog, options.expire);
            }
            if (options.propagate) {
              this._blog.next(blog);
            }
            // Record the cache hit.
            this._cacheService.updateStats(true);
            return of(blog);
          }
        }
      }
      // Record the cache miss.
      this._cacheService.updateStats(false);
    }
    // Otherwise, load the blog from the backend
    return this._httpClient.get<Blog>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/blogs/slug/${blogSlug}`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of(null);
        }),
        // Handle the logic of the cache and the propagation
        tap((blog: Blog) => {
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'blog', cacheParams, blog, options.expire);
          }
          if (options.propagate) {
            this._blog.next(blog);
          }
        })
      );
  }

  /**
   * Retrieves a list of blogs based on the provided filters.
   * @param filters - The filters to apply to the search.
   * @param options - The options related to cache and propagation.
   * @returns The requested list of blog objects.
   */
  getBlogs(filters: any, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<Blog[]> {
    // If there is no merchant, return an empty array
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of([]);
    }
    // If the blogs are already loaded, return them
    const cacheParams: any = { merchantId: this._merchant._id, filters };
    if (options.cache && this._cacheService.has(this._cacheNamespace, 'blogs', cacheParams)) {
      const blogs: Blog[] = this._cacheService.get(this._cacheNamespace, 'blogs', cacheParams);
      if (options.forcePropagate) {
        this._blogs.next(blogs);
      }
      return of(blogs);
    }
    // Otherwise, load the blogs from the backend
    return this._httpClient.post<Blog[]>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/blogs/search`,
      filters,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of([]);
        }),
        // Handle the logic of the cache and the propagation
        tap((blogs: Blog[]) => {
          if (options.concatenateResults && this._blogs.value != null) {
            blogs = this._blogs.value.concat(blogs);
          }
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'blogs', cacheParams, blogs, options.expire);
          }
          if (options.propagate) {
            this._blogs.next(blogs);
          }
        })
      );
  }

  /**
   * Returns the number of blogs that match the given filters.
   * @param filters - The filters to apply to the query.
   * @param options - The options related to cache and propagation.
   * @returns The number of blogs that match the given filters.
   */
  countBlogs(filters: any, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<number> {
    // If there is no merchant, return 0
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(0);
    }
    // If the blog count is already loaded, return them
    const cacheParams: any = { merchantId: this._merchant._id, filters };
    if (options.cache && this._cacheService.has(this._cacheNamespace, 'blogCount', cacheParams)) {
      const blogCount: number = this._cacheService.get(this._cacheNamespace, 'blogCount', cacheParams) || 0;
      if (options.forcePropagate) {
        this._blogCount.next(blogCount);
      }
      return of(blogCount);
    }
    // Otherwise, load the blog count from the backend
    return this._httpClient.post<number>(
      `${this._environmentService.getApiUrl()}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/blogs/count`,
      filters,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of(0);
        }),
        // Handle the logic of the cache and the propagation
        tap((count: number) => {
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'blogCount', cacheParams, count, options.expire);
          }
          if (options.propagate) {
            this._blogCount.next(count);
          }
        })
      );
  }

  /**
   * Gets the reading time for the given HTML string.
   * @param html The HTML string to get the reading time for.
   * @returns An object that contains the reading time in minutes and seconds.
   */
  getReadingTime(html: string): ReadingTime {
    // Divide the words by 200 (the average reading speed)
    const wordCount: number = this._countWordsInHtml(html) / 200;
    const minutes: number = Math.ceil(wordCount);
    const seconds: number = Math.floor((wordCount - Math.floor(wordCount)) * 60);
    return { minutes, seconds };
  }
  
}